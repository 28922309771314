import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '@components/layout';
import GridHome from '@components/grid-home';
import HeroPage from '@components/hero-page';

export default function Food({ data: { contentfulGallery }, location }) {
  return (
    <Layout location={location}>
      <Helmet>
        <title>Lookout Content - Food</title>
        <link rel="canonical" href="https://lookoutcontent.com" />
      </Helmet>
    </Layout>
  );
}

export const pageQuery = graphql`
  fragment ProjectFields on ContentfulProject {
    slug
    client {
      name
    }
    title
    thumbnail {
      file {
        url
      }
    }
  }

  query {
    contentfulGallery(name: { eq: "Food" }) {
      items {
        ...ProjectFields
      }
    }
  }
`;
